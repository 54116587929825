<template>
  <div>
    <div class="item_view" v-for="(item, index) in dataList" :key="`key_${index}`">
      <div class="title_view">{{ item.title }}<span>{{ index + 1 }}</span></div>
      <div class="content_tx" v-for="(item1, idx1) in item.list" :key="`k_${idx1}`">
        <div class="tx_title">{{ item1.key }}：</div>
        <div class="tx_content">
          <div v-for="(item2, idx2) in item1.contentList" :key="`k2_${idx2}`">{{ item2 || '-' }}</div>
          <div v-if="!item1.contentList || item1.contentList.length == 0">-</div>
        </div>
      </div>
    </div>
    <el-empty v-if="dataList.length == 0" description="暂无数据" :image="require('@/assets/icon_empty.png')"></el-empty>
  </div>
</template>

<script>
import API from '@/common/axios';
export default {
  data() {
    return {
      dataList: []
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      API({
        url: "http://aiapi.biyao.com/llm/v1/model/user/nonShoppingFocus",
        data: {
          externalUserId: this.$parent.selectUserInfo.externalUserid
        }
      }).then(res => {
        if (res.code == 200) {
          this.dataList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.item_view {
  padding: 20px;
  color: #333;
  border-bottom: 1px solid var(--boder-color);

  .title_view {
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
  }

  .content_tx {
    padding: 10px 0;
    font-size: 14px;
    display: flex;
    line-height: 20px;
    border-bottom: 1px dashed var(--boder-color);

    .tx_title {
      width: 100px;
      overflow: hidden;
      font-weight: 500;
    }

    .tx_content {
      width: calc(100% - 110px);
      margin-left: 10px;
      text-align: justify;
      word-break: break-all;

      div:not(:first-child) {
        margin-top: 6px;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>